@import "../../styles/typography.scss";
@import "../../styles/variables.scss";

@keyframes from-top {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.course {
  background: #f9f9f9;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 7px;
  box-sizing: content-box;
  max-width: 350px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  width: 100%;
  .course__text {
    height: calc( 100% - 240px);
    overflow: hidden;
    padding: 0 20px;
    position: relative;
  }
  .course-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    .icon {
      width: 30px;
      height: 30px;
      background-image: url(../../icons/svg/info.svg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .info-content {
      animation-name: from-top;
      overflow-y: auto;
      animation-duration: 0.2s;
      position: absolute;
      width: 250px;
      height: 400px;
      background: $palette_controlAction__activeBG;
      padding: 15px 20px;
      left: 0;
      top: 0;
      z-index: 1000;
      p {
        color: $palette_text__secondary;
        margin-bottom: -5px;
      }
      p.value {
        color: black;
        font-size: 20px;
        margin-bottom: 13px;
        line-height: 1.2;
      }
    }
  }
  .buttonRow {
    background-color: #339388;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    position: relative;
    width: 100%;
    &.finished {
      background-color: white;
      a {
        width: 100%;
      }
    }
    &.failed {
      background-color: rgba(200, 50, 50, 0.7) !important;
    }

    span {
      color: black;
      font-size: 13px;
      font-weight: 500;
    }
    .startCourseButton {
      color: black;
      width: 100%;
      height: 37px;
      &.finished {
        opacity: 0.3;
        color: white !important;
      }
      &.failed {
        color: white !important;
        background: transparent;
      }
    }
    .disableCourseButton {
      color: red;
      width: 50%;
      height: 37px;
    }
  }
  .topRow {
    display: flex;
    flex-direction: row;
  }
  .timeOfCourse {
    background-color: white;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    left: -135px;
    margin-left: 10px;
    padding-right: 10px;
    padding-left: 10px;
    position: relative;
    top: -180px;
    width: 28%;
    .symbol {
      padding-right: 10px;
      font-weight: 1000;
    }
  }
  .rewardPoints {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    position: absolute;
    height: auto;
    background-color: rgba(255,255,255,0.6);
    top: 3%;
    right: 5%;
    font-size: 16px;
    white-space: nowrap;
    &.complete {
      background: rgba(96, 197, 100, 0.7);
      left: 40%;
    }
    .symbol {
      padding-right: 10px;
      font-weight: 1000;
    }
  }
  span {
    @include secondary_text;
  }
  .theme {
    padding: 0 20px;
  }

  &.addCourse {
    border: 1px dashed rgba(0,0,0,0.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    grid-row-start: 1;
    grid-row-end: 11;
    .addIcon {
      text-align: center;
      font-size: 50px;
      margin: auto;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    .cardItems {
      margin: auto;
      display: flex;
      flex-direction: column;
      @include button_text_1;
    }
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .title {
    @include primary;
    margin-bottom: 3%;
    line-height: 1.5;
  }
  .desc {
    @include primary;
    font-weight: 200;
    line-height: 1.4;
  }
  .count {
    @include primary;
    letter-spacing: 0;
    font-weight: 200;
  }
}
