.autocomplete {
  &-manufacturer {
    &__suggestions {
      background-color: #EEEFEF !important;
      box-shadow: 0 4px 8px rgba(84, 110, 122, 0.24);
      border: 1px solid #E0E0E0 !important;
      padding: 0 !important;
    }
    &__suggestion {
      border-top: 1px solid #E0E0E0;
      &:first-child {
        border-top: none;
      }
    }
  }
}

.autocomplete-input {
  position: relative;
  &.opened {
    input {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .suggestions {
      display: block;
    }
  }
  .suggestions {
    display: block;
    z-index: 9999;
    border: 1px solid #F7F7F7;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    max-height: 200px;
    overflow: auto;
    background: white;
    position: absolute;
    width: 100%;
    padding: 10px 0;
    &.requesting {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .suggestion {
      padding: 3px 15px;
      transition: 0.3s;
      &:hover {
        background: rgba(0,0,0,0.05);
        cursor: pointer;
      }
    }
  }
}
