$palette_amber__700: #ffa000;
$palette_green__500: #4caf50;
$palette_green__600: #43a047;
$palette_green__700: #388e3c;

$palette_primary__light: #00796B;
$palette_primary__main: #FFFFFF;
$palette_primary__dark: #00796B;
$palette_primary__contrastText: #263238;

$palette_secondary__light: #339388;
$palette_secondary__main: #00796B;
$palette_secondary__dark: #00544a;
$palette_secondary__contrastText: #fff;

$palette_accent__light: #3492ca;
$palette_accent__main: #0277BD;
$palette_accent__dark: #015384;
$palette_accent__contrastText: #fff;

$palette_secondaryAccent__light: #950000;
$palette_secondaryAccent__main: #D50000;
$palette_secondaryAccent__dark: #dd3333;
$palette_secondaryAccent__contrastText: #fff;

$palette_controlAction__active: #00796B;
$palette_controlAction__hover: #00544a;
$palette_controlAction__disable: #979797;
$palette_controlAction__activeBG: #E1EFEE;

$palette_error__dark: #d32f2f;

$palette_background__paper: #fff;

$palette_text__secondary: rgba(0, 0, 0, 0.54);

$spacing_unit: 8;

$transitions_easing__sharp: cubic-bezier(0.4, 0, 0.6, 1);
$transitions_duration__leavingScreen: 195;
$transitions_duration__enteringScreen: 225;
