.prompt {
  &-delete {
    padding: 18px 24px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 400px;
  }
  &__control {
    display: grid;
    justify-content: space-between;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
  }
  &__btn {
    border-radius: 3px;
    &_cncl {
      background: #00796B;
      box-shadow: 0 4px 8px rgba(0, 121, 107, 0.24);
      color: white;
      padding: 12px 0;
      border: none;
      &:hover {
        cursor: pointer;
        background-color: #005749;
      }
    }
  }
}

.notification {
  margin: 20px 0;
  text-align: center;
  &__title {
    font-size: 34px;
    color: #D72638;
    letter-spacing: 0.25px;
    font-family: Roboto;
    font-style: normal;
  }
  &__text {
    margin-top: 30px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
  }
}