@import "../../styles/typography.scss";

.testInfo__content {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  .statisticInfo, .timer, .questionCounter {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
  }
  .statisticInfo {
    margin: 25px;
  }
  .progressBar {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .secondary_text {
    @include secondary_text;
  }
  .progressInfo {
    &.no-time {
      p {
        margin-top: 200px;
        font-size: 20px;
        text-align: center;
      }
    }
    &.disabled {
      color: #bababa;
    }
  }
}
