@import "../styles/variables.scss";
@import "../styles/typography.scss";

@keyframes from-bottom  {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes signing {
  0% { background-color: $palette_controlAction__hover; }
  50% { background-color: $palette_secondary__light; }
  100% { background-color: $palette_controlAction__hover; }
}

.auth {
  width: 100vw;
  height: 100vh;
  background-image: url(../static/imgs/AuthBackground.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .agreement {
    display: flex;
    p {
      font-size: 10px;
      line-height: 1;
    }
  }
  div.info {
    position: absolute;
    top: 24%;
    max-width: 320px;
    background: #f0f0f0;
    border-radius: 7px;
    padding: 15px;
    display: flex;
    opacity: 1;
    transform: none;
    animation-name: from-bottom;
    animation-duration: 1s;
    &.singUp {
      top: 10%;
    }
    p {
      span {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
  p.input-info {
    font-size: 12px;
    margin-bottom: -10px;
    margin-top: 10px;
  }
  .authInfo {
    margin-top: 30px;
    width: 320px !important;
    background: white;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
    padding: 10px 15px;
    p {
      font-size: 17px;
      text-align: center;
    }
  }
  .card {
    width: 320px !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .mode_select {
      padding: 10px 0;
      border-bottom: 1px solid #dadada;
    }
    .without_invite {
      padding: 20px;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 20px;
        text-align: center;
      }
    }
    // &.sign_in {
    //   height: 320px;
    //   &.with_code {
    //     height: 380px;
    //   }
    // }
    // &.sign_up {
    //    height: 480px;
    // }
    .cardContent {
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .authRemember {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .MuiTypography-body2-38 {
      font-size: 20px;
      color: #000;
      font-weight: 500;
      letter-spacing: 1px;
    }
    .MuiFormLabel-root-80  {
      font-size: 12px;
    }
    .MuiInputLabel-outlined-80.MuiInputLabel-shrink-77 {
      transform: translate(14px, -6px) scale(0.9);
      color: $palette_primary__contrastText;
    }
    .MuiInputBase-input-111{
      font-size: 14px;
    }
    .MuiFormLabel-root-81 {
      font-size: 14px;
    }
    .MuiInputBase-root-100 {
      font-size: 16px;
    }
    .switch {
      margin-left: -12px;
    }
    .switchText{
      color: $palette_secondary__main;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .cardAction{
      padding-top: 0;
      padding-bottom: 0;
      // margin-top: auto;
    }
    .authButton {
      color: #fff;
      width: 272px;
      max-width: none;
      margin: 0 12px 24px 12px;
      text-transform: none;
      font-size: 14px;
      background-color: $palette_controlAction__active;
      &:hover {
        background-color: $palette_controlAction__hover;
      }
      &.not_valid {
        background-color: $palette_controlAction__disable;
      }
      &.error {
        background-color: $palette_secondaryAccent__dark;
      }
      &.requesting {
        animation-name: signing;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
  }

  .pos {
    margin-bottom: 12;
  }
}

@media (max-device-width: 1023px) {
  .auth {
    .card.sign_in {
      height: 95vh;
    }
    .card.sign_in.with_code {
      height: 95vh;
    }
    .card {
      display: flex;
      flex-direction: column;
      .mode_select {
        padding: 0;
      }
      .cardContent {
        padding-top: 5px;
        padding-bottom: 0;
        p {
          margin-bottom: 0;
        }
        // div {
        //   margin-top: 8px;
        // }
        .authRemember {
          margin-top: 0;
        }
      }
      .cardAction {
        margin-left: auto;
        margin-right: auto;
        // margin-top: auto;
        button {
          margin-bottom: 5px;
        }
      }
    }
  }
}
