@import "../../styles/typography.scss";

.start-page__message__zero-attempt {
  font-family: Roboto;
  text-align: center;
  margin-top: 35vh;
}
.stepBar {
  display: flex;
  height: 40px;
  background-color: #E1E2E1;
  .stepBarPoint {
    align-self: flex-end;
    margin-left: 25px;
    padding-bottom: 9px;
    @include training_h2;
    font-weight: normal;
    font-size: 14px;
    &.slideType {
      font-size: 14px;
      text-transform: uppercase;
    }
    &.current {
      color: #009688;
      border-bottom: solid 2px #009688;
    }
  }
  .cancelButton {
    margin-left: auto;
    @include training_h2;
    font-weight: normal;
    font-size: 14px;
  }
}

.course__wrapper {
  width: 100%;
  margin: 20px;
  display: flex;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  overflow: hidden;
  .content__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;

    .info__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-top: 20px;
      text-align: center;
      line-height: 3;
      .agreementRow {
        display: flex;
        margin-top: auto;
        justify-content: center;
      }
      .slidesPreview {
        margin-bottom: 20px;
        justify-content: center;
      }
      .slide {
        max-width: 100px;
        min-width: 75px;
        user-select: none;
        margin: 10px 0 10px 10px;
        flex-grow: 1;
        height: 64px;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        text-align: center;
        background-repeat: no-repeat;
        background-position: center;
        p {
          margin-top: 70px;
        }
        &.info {
          background-image: url(../../static/imgs/info_icon.png);
        }
        &.youtube_video {
          background-image: url(../../static/imgs/video_icon.png);
        }
        &.text {
          background-image: url(../../static/imgs/text_icon.png);
        }
        &.pdf {
          background-image: url(../../static/imgs/pdf_icon.png);
        }
        &.audio {
          background-image: url(../../static/imgs/audio_icon.png);
        }
        &.image_gallery {
          background-image: url(../../static/imgs/photo_icon.png);
        }
        &.test {
          background-image: url(../../static/imgs/test_icon.png);
        }
        &.poll {
          background-image: url(../../static/imgs/poll.svg);
        }

        span {
          position: relative;
          top: 70px;
        }
      }
    }
    .actionFooter {
      width: 100%;
      border-top: 1px solid #DFDFDF;
      @include button_text_1;
      .startButton {
        float: right;
        background-color: rgba(0, 150, 136, 0.7);
        &:hover {
          background-color: rgba(0, 150, 136, 1);
        }
      }
    }
  }

  .finished__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    .actionFooter {
      button {
        background: rgba(0, 150, 136, 0.7);
        &:hover {
          background: #009688;
        }
      }
    }
    .pointProgressBar, .progressBar {
      display: flex;
      width: 60%;
      margin: auto;
      margin: 20px auto;
      .positivePoint {
        background-color: #339388;
        height: 4px;
        width: 100%;
        margin-left: 5px;
      }
      .failPoint {
        background-color: #d32f2f;
        height: 4px;
        width: 100%;
        margin-left: 5px;
      }
      .negativePoint {
        background-color: #ADADAD;
        height: 4px;
        width: 100%;
        margin-left: 5px;
      }
    }
    .statisticIcons {
      display: flex;
      &.mobileIcon {
        padding-top: 0;
      }
      .certificateIcon {
        background-image: url(../../static/imgs/certificate.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        align-self: center;
        width: 50%;
        height: 93px;
        margin: auto;
        text-align: center;
        line-height: 150px;
        white-space: nowrap;
        @include colorText;
      }
      .bonusIcon {
        background-image: url(../../static/imgs/Bonus.png);
        background-repeat: no-repeat;
        background-position-x: center;
        align-self: center;
        width: 50%;
        height: 93px;
        margin: auto;
        text-align: center;
        line-height: 150px;
        white-space: nowrap;
        @include colorText;
      }
      .gap {
        width: 200px;
      }
    }
    .successIcon {
      background-image: url(../../static/imgs/successIcon.png);
      background-repeat: no-repeat;
      align-self: center;
      width: 91px;
      height: 93px;
      padding-bottom: 10px;
      &.mobileIcon {
        margin-top: 3vh;
      }
    }
    .failedIcon {
      background-image: url(../../static/imgs/failIcon.png);
      background-repeat: no-repeat;
      align-self: center;
      width: 91px;
      height: 93px;
      margin-top: 15vh;
    }
    p {
      font-size: 27px;
      &.time {
        font-size: 17px;
      }
    }
  }
  .toCertificateButton {
    height: 50px;
    float: left;
  }
  .toPageButton {
    height: 50px;
    float: right;
  }
  .slidesPreview {
    display: flex;
  }
  .addButton {
    margin: 10px;
    width: 96px;
    height: 64px;
    border: dashed 1px #B9B8B8;
    border-radius: 5px;
    background-image: url(../../static/imgs/add_checkbox_question.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .course_titleImg {
    width: 60%;
    align-self: center;
  }

  .startButton {
    height: 50px;
  }

  .course {
    min-width: 250px;
    height: 100% !important;
    margin-left: 0 !important;
    border: none !important;
    border-right: 1px solid rgba(0,0,0,0.1) !important;
    .buttonRow {
      display: none;
    }
    .topRow {
      display: flex;
      flex-direction: row;
    }
    .timeOfCourse {
      background-color: white;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      left: -135px;
      margin-left: 10px;
      padding-right: 10px;
      padding-left: 10px;
      position: relative;
      top: -180px;
      width: 28%;
      .symbol {
        padding-right: 10px;
        font-weight: 1000;
      }
    }
    .rewardPoints {
      display: flex;
      padding-right: 10px;
      padding-left: 10px;
      position: relative;
      width: 45%;
      height: auto;
      background-color: white;
      top: -180px;
      left: 44%;
      font-size: 16px;
      white-space: nowrap;
      .symbol {
        padding-right: 10px;
        font-weight: 1000;
      }
    }
    span {
      @include secondary_text;
    }
    .theme {
      padding: 0 20px;
    }
    //align-items: center;
    width: 250px;
    height: auto;
    margin-left: 30px;
    // margin-bottom: 25px;
    border: 1px solid rgba(0,0,0,0.1);
    &.addCourse {
      border: 1px dashed rgba(0,0,0,0.1);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .addIcon {
        text-align: center;
        font-size: 50px;
        margin: auto;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      .cardItems {
        margin: auto;
        display: flex;
        flex-direction: column;
        @include button_text_1;
      }
    }
    border-radius: 5px;
    box-sizing: content-box;
    overflow: hidden;
    background: #f9f9f9;
    &.accessed {
      width: 250px;
      height: auto;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .access-message {
        @include secondary_text;
        padding: 0 20px;
        margin-top: -10px;
      }
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    .title {
      padding: 0 20px;
      @include primary;
    }
    .desc {
      letter-spacing: 0 !important;
      font-weight: 200 !important;
      padding: 0 20px 10px;
      @include primary;
    }
    .count {
      letter-spacing: 0 !important;
      font-weight: 200 !important;
      padding: 0 20px;
      @include primary;
    }
  }
}
