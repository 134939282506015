
.slideContent {
  .slidesSidebar {
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    .slider {
      padding: 22px 0;
    }
    .text {
      padding: 10px 0;
    }
  }
}
.mainField {
  display: flex;
  flex-direction: column;
  //overflow-x: hidden;
  overflow-y: auto;
  .testConstructor__wrapper {
    text-align: center;
    margin: 40px;
    .contentWrapper {
      position: relative;
    }
  }
  .addButton {
    margin: auto;
    width: 96px;
    height: 64px;
    border: dashed 2px #B9B8B8;
    border-radius: 5px;
    background-image: url(../../../static/imgs/add_checkbox_question.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .addButton_error {
    border: dashed 2px #f44336;
  }
  .addPhotoQuestion {
    background-image: url(../../../static/imgs/add_photo.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .addCollateQuestion {
    background-image: url(../../../static/imgs/addCollate.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .contentWrapper {
    padding: 14px;
    margin: 20px 20px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: max-content;
    .button_error {
      color: #f44336;
    }
    .question {
      .deleteAnswer {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-image: url(../../../static/imgs/trash-solid.png);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .dialog__question {
      width: 60%;
    }
    .question__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .deleteQuestion {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-image: url(../../../static/imgs/trash-solid.png);
        background-repeat: no-repeat;
        background-position: left center;
      }
    }

    &_delete {
      padding: 20px;
      background: white;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    //
    //
    .trashContainer {
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-image: url(../../../static/imgs/trash-solid.png);
      background-repeat: no-repeat;
      background-position: left center;
      position: absolute;
      top: 5px;
      right: 5px;
      // margin-bottom: -30px;
    }
    .react-mde {
      &.invalidValue {
        border: 2px solid #f44336;
      }
      &.testText {
        .mde-text {
          height: 100px !important;
        }
      }
      button {
        padding: 5px;
      }
      .grip {
        display: none;
      }
      .mde-text {
        height: 350px !important;
        text-align: center;
      }
      .mde-preview {
        width: 504px;
        height: 350px;
        overflow-y: auto;
      }
    }
    .text {
      padding: 0 0 15px 0;
      // width: 50%;
      &.full {
        width: 60%;
      }
    }
    .questions {
      width: 60%;
      .question {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .delete {
          font-size: 60px;
          font-weight: 100;
          cursor: pointer;
        }
        .text {
          width: 75%;
        }
      }
    }
    .photoInputPreview {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 5px;
      .correctPhoto {
        position: relative;
        bottom: 20px;
        left: 50px;
        svg {
          background: rgba(255, 255, 255, 0.5);
        }
        &:hover {
          transform: scale(1.2);
        }
      }
      div.previewContainer {
        position: relative;
        &:hover {
          div.delete {
            opacity: 0.7;
          }
        }
        div.delete {
          opacity: 0;
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: white;
          background-image: url(../../../icons/svg/delete.png);
          background-size: 90%;
          background-position: center;
          width: 16px;
          height: 16px;
          transition: 0.2s;
          cursor: pointer;
          border-radius: 100px;
          &:hover {
            opacity: 0.8;
            transform: scale(1.2);
          }
        }
        img {
          border: 1px solid #e6e6e6;
          width: 10vh;
          height: 10vh;
          object-fit: cover;
          margin: 5px;
        }
      }
    }
    .photoInput__wrapper {
      .tipRow {
        width: 100%;
      }
      .photoInput {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      .photoInput + label {
        margin: auto !important;
        width: 100px;
        height: 70px;
        border: 2px dotted #DADADA;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        font-size: 35px;
        color: #DADADA;
        transition: 0.2s;
        border-radius: 5px;
        margin: 0 25px;
      }
      .photoInput + .input_error {
        border: 2px dotted #f44336;
      }
      .photoInput + label:hover {
        cursor: pointer;
        transform: scale(1.1);
        border-radius: 10px;
      }
    }
    .photoInfo {
      background: rgba(2, 119, 189, 0.1);
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 50px;
      height: 39px;
    }
  }
}

.question {
  &-delete {
    &__text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 20px;
    }
    &__restore {
      &:hover {
        cursor: pointer;
        color: #3492ca;
        text-decoration: underline;
      }
    }
  }
}
