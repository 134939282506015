.profile__wrapper {
  background-color: rgb(250, 250, 250);
  padding-right: 30px;
  font-family: Roboto, sans-serif;
  .autocomplete-input {
    width: 100%;
  }
  input, textarea {
    padding: 0 15px;
    width: 100%;
    height: 40px;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    background: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    color: #999999;
    &.modyfied {
      border-left: 5px solid #F2994A;
      padding-left: 11px;
      &:focus {
        border-color: #F2994A;
        border-left: 5px solid #F2994A;
      }
    }
    &:focus {
      border: 1px solid #339388;
      box-shadow: 0px 0px 3px #339388;
    }
  }
  textarea {
    resize: none;
    padding: 10px;
    height: 110px;
  }
  .radio_group {
    input {
      display: none;
      &:disabled + label {
        border-color: #bdbdbd;
      }
    }
    input:checked + label {
      background-color: #00796B;
      color: #FFF;
    }
    input:checked:disabled + label {
      background-color: #bdbdbd;
    }
    label {
      background-color: #FFF;
      border: 1px solid #00796B;
      color: #BFBFBF;
      display: inline-block;
      min-height: 36px;
      width: 50%;
      padding: 7px;
      text-align: center;
    }
    :nth-child(2) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    :nth-child(4) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .main__button {
    background-color: #00796B;
    border: none;
    border-radius: 6px;
    color: #FFF;
    min-height: 36px;
    min-width: 167px;
    padding: 5px;
    font-size: 16px;
    white-space: nowrap;
    &:disabled {
      background-color: #bdbdbd;
    }
  }
  .save__button {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
  }
  .row {
    display: flex;

    .info__block:nth-child(2) {
      margin: 24px 24px 0;
    }
    .gender, .balance {
      flex-direction: column;
    }
  }
  .full_width_card {
    flex-direction: column;
  }
  .icon {
    cursor: pointer;
    svg {
      height: 100%;
    }
  }
  .info__block {
    display: flex;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    border: 1px solid #F7F7F7;
    border-radius: 5px;
    margin-top: 24px;
    padding: 24px;
    .workPlaces__row {
      display: flex;
      margin-bottom: 12px;
      p {
        width: 100%;
      }
    }
    .institution {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
    .row {
      width: 100%;
      .autocomplete-input {
        margin-right: 24px;
      }

    }
  }
  .contact {
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 0.5fr;
  }
  .avatar_field {
    margin-left: 12px;
  }
  .title {
    font-size: 14px;
  }
  .secondary_text {
    color: #BFBFBF;
    margin-bottom: 12px;
  }
  .balls {
    font-size: 48px;
    line-height: 36px;
    margin-bottom: 0;
    white-space: nowrap;
  }
  .personInfo {
    justify-content: space-between;
  }
  .roleInfo {
    flex-direction: column;
  }
}
