@import "../styles/typography.scss";



.constructor__wrapper {
  height: 100%;
  .modes {
    display: flex;
    align-items: flex-end;
    a {
      text-decoration: none;
    }
    .mode {
      user-select: none;
      cursor: pointer;
      margin: 0 20px;
      border-bottom: solid 2px white;
      padding-bottom: 5px;
      transition: 0.3s;
      @include training_h2;
      &_first {
        margin-left: 0;
      }
      &.disable {
        cursor: default;
        opacity: 0.4;
      }
      &.current {
        color: #009688;
        border-bottom: solid 2px #009688;
      }
    }
  }
  .constructor {
    background: rgb(245,245,246);
    height: 100%;
    padding: 20px;
    padding-right: 0;
    &__header {
      display: flex;
      padding: 0 24px;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    &__delete {
      margin-bottom: 4px;
    }
    .users {
      height: 96%;
    }
    .editSlides {
      height: 100%;
    }
    .constructor_info__wrapper {
      display: flex;
      height: 97%;
      width: 99%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      overflow: hidden;
      .focusedTextColor {
        color: #009688;
      }
      .focusedBottomBorder::after {
        border-bottom: solid 2px #009688;
      }
      .infoSetting {
        background-color: white;
        border-right: solid 1px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        max-width: 280px;
        text-align: center;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        .certificate {
          display: flex;
          margin-bottom: 20px;
          margin-top: 20px;
          align-items: center;
          .pdfInput__wrapper {
            display: flex;
            margin-right: 20px;
            // margin-top: 100px;
            .pdfInput {
              width: 0.1px;
              height: 0.1px;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              z-index: -1;
            }
            .pdfInput + .emptyField {
              margin: auto !important;
              width: 50px;
              height: 50px;
              border: 2px dotted #DADADA;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 100;
              font-size: 35px;
              color: #DADADA;
              transition: 0.2s;
              border-radius: 5px;
              margin: 0 25px;
            }
            .pdfInput + label:hover {
              cursor: pointer;
              transform: scale(1.1);
              border-radius: 10px;
            }
            .pdfInputPreview {
              display: flex;
              flex-direction: column;
              img {
                border: 1px solid #e6e6e6;
                margin: auto;
                margin-top: 20px;
                width: auto;
                height: 200px;
                object-fit: contain;
              }
            }
          }
        }
        .inputSmallPadding {
          padding: 10.5px 14px;
        }
        .labelFocusedColor {
          color: #009688;
        }
        .labelCenterPosition {
          color: red;
          // transform: translate(14px, 12px) scale(1);
          // transform: translate(14px, 0px) scale(0.75);
          // top: -8px;
        }
        .inputFocusedBorder {
          fieldset {
          }
          background-color: red;
          border: solid 2px #009688;
          border-radius: 4px;
        }
        .switchCheckedColor {
          color: #009688;
          & + .bar {
            background-color: #009688;
          }
        }
        .slider {
          padding: 22px 0;
          button {
            background-color: #009688;
            width: 18px;
            height: 18px;
          }
        }
        .switchRow {
          display: flex;
          align-items: center;
          margin-left: -15px;
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-size: 14px;
            letter-spacing: 0.0357143em;
            color: #009688;
            text-align: left;
          }
        }
      }
      .mainField {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        height: 100%;
        width: 100%;
        .courseDescription {
          h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            font-size: 24px;
            text-align: center;
            margin : 20px;
          }
          .input__wrapper {
            display: flex;
            .photoInput__wrapper {
              margin: 20px;
              img {
                width: 250px;
                height: 200px;
                object-fit: cover;
                border-radius: 5px;
              }
            }
            .description__wrapper {
              width: 100%;
              div {
                margin-top: 20px;
              }
              .wideInput {
                width: 95%;
              }
            }
          }
        }
      }

      .photoInput__wrapper {
        display: flex;
        // margin-top: 100px;
        .photoInput {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
        .photoInput + .emptyField {
          margin: auto !important;
          width: 200px;
          height: 180px;
          border: 2px dotted #DADADA;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 100;
          font-size: 35px;
          color: #DADADA;
          transition: 0.2s;
          border-radius: 5px;
          margin: 0 25px;
        }
        .photoInput + .errorPhotoInput {
          border: 2px dotted #f44336;
        }
        .photoInput + label:hover {
          cursor: pointer;
          transform: scale(1.1);
          border-radius: 10px;
        }
        .photoInputPreview {
          display: flex;
          flex-direction: column;
          img {
            border: 1px solid #e6e6e6;
            margin: auto;
            margin-top: 20px;
            width: auto;
            height: 200px;
            object-fit: contain;
          }
        }
      }
    }
    .noSlides {
      width: 100%;
      height: calc(100vh - 300px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slidesPreview {
      display: flex;
      margin-left: -20px;
      margin-top: -20px;
      &.requesting {
        // background: black;
        .slide {
          background: transparent;
          box-shadow: none;
          border: 1px dashed rgba(0,0,0,0.3);
          &.current {
            box-shadow: none;
            transform: none;
          }
          &:hover {
            box-shadow: none;
            transform: none;
          }
        }
      }
      .items {
        display: flex;
        width: 100%;
      }

      .leftArrow {
        cursor: pointer;
        background-image: url(../static/imgs/keyboard_arrow_right_.png);
        background-repeat: no-repeat;
        align-self: center;
        transform: rotate(180deg);
        margin-left: 24px;
        width: 24px;
        height: 24px;
      }
      .rightArrow {
        cursor: pointer;
        background-image: url(../static/imgs/keyboard_arrow_right_.png);
        background-repeat: no-repeat;
        align-self: center;
        margin: 0 10px 0 10px;
        width: 24px;
        height: 24px;
      }
      .slide {
        max-width: 100px;
        min-width: 75px;
        user-select: none;
        cursor: pointer;
        margin: 10px 0 10px 10px;
        flex-grow: 1;
        height: 64px;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        @include secondary_text;
        text-align: center;
        &:hover {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          transform: scale(1.02);
        }
        &.current {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          transform: scale(1.05);
        }
        &.hidden {
          transition-delay: 0.1s;
          cursor: move;
          opacity: 0;
        }
        span {
          position: relative;
          top: 70px;
        }
      }
      .addSlide {
        position: relative;
        cursor: pointer;
        margin: 25px 0 10px 10px;
        width: 96px;
        height: 64px;
        border: dashed 1px #B9B8B8;
        border-radius: 5px;
        background-image: url(../static/imgs/Shape.png);
        background-repeat: no-repeat;
        background-position: center;
        .newSlideMenu {
          position: absolute;
          background: white;
          width: 150px;
          padding: 10px;
          top: -1px;
          border-radius: 5px;
          left: -1px;
          z-index: 1;
          * {
            margin-bottom: 5px;
            cursor: pointer;
          }
        }
      }
      .trashContainer {
        cursor: no-drop;
        margin: 24px;
        margin-right: 20px;
        margin-left: auto;
        width: 200px;
        height: 64px;
        border: dashed 1px #B9B8B8;
        border-radius: 5px;
        background-image: url(../static/imgs/trash-solid.png);
        background-repeat: no-repeat;
        background-position: left center;
        @include secondary_text;
        padding-left: 40px;
        text-align: center;
        display: flex;
        align-items: center;
      }
      .info {
        background-image: url(../static/imgs/info_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .youtube_video {
        background-image: url(../static/imgs/video_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .text {
        background-image: url(../static/imgs/text_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .pdf {
        background-image: url(../static/imgs/pdf_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .audio {
        background-image: url(../static/imgs/audio_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .image_gallery {
        background-image: url(../static/imgs/photo_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .poll {
        background-image: url(../static/imgs/poll.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }
      .test {
        background-image: url(../static/imgs/test_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
      .survey {
        background-image: url(../static/imgs/survey_icon.png);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .slideContent {
      display: flex;
      height: 85%;
      .slidesSidebar {
        background-color: white;
        width: 375px;
        margin-top: 20px;
        padding: 20px;
        border-top-left-radius: 5px;
      }
      .mainField {
        background-color: #E6E6E6;
        width: 100%;
        margin-top: 20px;
        margin-right: 20px;
        border-top-right-radius: 5px;
        overflow-y: auto;
      }
    }
    .constructor_info__wrapper .actionFooter {
      width: calc(100% - 64px);
      margin-left: 0;
      right: 0;
    }
    .actionFooter {
      position: absolute;
      margin-left: -20px;
      height: 51px;
      width: calc(100% - 64px);
      bottom: 0;
      padding-right: 10px;
      padding-bottom: 15px;
      background-color: #f5f5f6;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include button_text_1;
      &.loading {
        button {
          opacity: 0.4;
        }
      }
    }
  }
}
