@keyframes loading_button {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.root__mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  transition: all 1s;
  a {
    text-decoration: none;
    * {
      text-decoration: none;
    }
  }
  .content {
    padding-left: 0;
    height: 100%;
    transition: 0.2s;

    &.smooth {
    }
  }
  
}
