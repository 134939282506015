@import "../../styles/typography.scss";

aside {
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 70px;
  width: 64px;
  background: white;
  transition: 0.2s;
  padding: 20px;
  @include secondary_text;
  .routes {
    display: none;
  }
  &.opened {
    width: 300px;
    height: calc(100% - 70px);
    z-index: 999;
    .routes {
      display: inline-block;
    }
  }

  .route {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .routes  {
      margin-left: 15px;
    }
  }
}
