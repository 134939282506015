.users {
  background: #fafafa;
  height: 100%;
  width: 99%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
  .applyCourse {
    text-transform: uppercase;
    cursor: pointer;
    margin-left: auto;
    padding: 0 20px;
  }
  .container {
    display: flex;
    background-color: #FFF;
    height: 100%;
    .filterMenu {
      display: flex;
      flex-direction: column;
      width: 320px;
      border-right: solid 1px rgba(0, 0, 0, 0.1);
      padding: 20px;
      button {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.4);
        text-transform: none;
        padding: 0;
        .plusSpan {
          font-size: 25px;
          margin-right: 10px;
        }
      }
      .inputSmallPadding {
        padding: 10.5px 14px;
      }
      .labelFocusedColor {
        color: #009688;
      }
      .focusedTextColor {
        color: #009688;
      }
      .focusedBottomBorder::after {
        border-bottom: solid 2px #009688;
      }
    }
    .audienceMenu {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: Roboto, sans-serif;
      font-style: Medium;
      font-size: 14px;
      opacity: 54;

      .audience-header {
        font-size: 24px;
        font-weight: 600;
        padding: 20px 35px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .audienceList {
        padding: 20px;
        padding-top: 0;
        height: 100%;
        overflow-y: auto;
        .audienceListItem {
          display: grid;
          grid-template-columns: 50px repeat(3, 1fr);
          border-bottom: dashed 1px rgba(0, 0, 0, 0.1);
          .primaryColor {
            color: #009688;
          }
          .userItems {
            padding: 10px;
            align-self: center;
            white-space: nowrap;
          }
        }
      }
      .actionFooter {
        position: absolute;
        // margin-left: -282px;
        right: 0;

        height: 51px;
        width: calc(100% - 64px);
        bottom: 0;
        padding-right: 10px;
        background-color: #f5f5f6;
        display: flex;
        justify-content: flex-end;
        button {
          height: 100%;
        }
        &.loading {
          button {
            opacity: 0.4;
          }
        }
        .fixHeight {
          height: 100%;
        }
        .switchCheckedColor {
          color: #009688;
          & + .bar {
            background-color: #009688;
          }
        }
        .switchRow p {
          align-self: center;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}
