.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);

  &__main {
    z-index: 99999;
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
  }

  &-material {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 20px 30px;
    background: white;
    opacity: 1 !important;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
  }
  &-tickets {
    background: white;
    padding: 20px 20px;
    position: relative;
    border-radius: 12px;
    p {
      font-size: 20px;
      margin-bottom: 10px;
    }
    input,
    textarea {
      border-radius: 5px;
      outline: 0;
      box-shadow: none;
      border: 1px solid #bfbfbf;
      padding: 10px;
      max-width: 400px;
      min-width: 400px;
    }
    textarea {
      height: 150px;
      margin-top: 7px;
      margin-bottom: 50px;
    }
    button {
      cursor: pointer;
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: inline-block;
      align: right;
      // margin-top: 17px;
      padding: 10px 20px;
      border: 0;
      outline: 0;
      background-color: #dfeeec;
      color: #999999;
      border-radius: 5px;
      &.loading {
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
    .close {
      background-image: url(../../static/imgs/close.png);
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 25px;
      width: 20px;
      height: 20px;
    }
  }
}
