@import "../../../styles/typography";

@keyframes poomph {
  0%    {
    transform: scale(1);
    opacity: 1;
  }
  50%   {
    transform: scale(1.3);
    opacity: 1;
  }
  100%  {
    transform: scale(1);
    opacity: 1;
  }
}

.target_wrapper {
  display: flex;
  width: 99%;
  height: 100%;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  .no_content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pharms_wrapper {
    border-right: 1px solid #eaeaea;
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    width: 370px;
    button {
      flex-grow: 1 !important;
      background-color: #00796B;
      color: white;
      &.save {
        width: 100%;
        margin-top: 10px;
        background: white;
        color: #00796B;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .pharmFinder {
      font-family: Roboto, sans-serif;
      font-size: 22px;
      color: currentColor;
      padding: 6px 10px;
      display: block;
      margin-bottom: 20px;
      min-width: 0;
      box-sizing: content-box;
      background: none;
      -webkit-tap-highlight-color: transparent;
      width: calc(100% - 20px);
      border: 1px solid #9a9a9a;
      border-radius: 9px;
    }
    .pharms {
      height: calc(100% - 200px);
      overflow-y: auto;
      .pharm {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        &:hover {
          background-color: rgba(0,0,0,0.1);
          border-radius: 10px;
          cursor: pointer;
          transition: 0.3s;
        }
        &.current {
          background-color: rgba(0, 121, 107, 0.1);
          border-radius: 10px;
          div.pharmacyIcon {
            background: rgba(0,121,107, .34);
            background-image: url(../../../static/imgs/pharmacy-selected.png);
            opacity: 0.4;
          }
        }
        div.pharmacyIcon {
          border-radius: 40px;
          width: 40px;
          height: 40px;
          background: #d7d7d7;
          background-position: center;
          background-image: url(../../../static/imgs/pharmacy.png);
          margin-right: 14px;
        }
        div {
          span {
            p {
              font-weight: 600;
              font-size: 12px !important;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #263238 !important;
              opacity: 0.54;
            }
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .links {
    height: calc(100% - 152px);
    overflow: auto;
    .invite {
      height: 80px;
      display: flex;
      padding: 0 20px;
      .block {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .link {
        flex-grow: 1;
        justify-content: flex-start;
        cursor: pointer;
        &:hover {
          div.withIcon {
            color: white;
            div.copyIcon { opacity: 1; }
          }
        }
        div.linkIcon {
          background: #d7d7d7;
          background-position: center;
          width: 36px;
          height: 36px;
          border-radius: 30px;
          margin-right: 20px;
          background-image: url(../../../static/imgs/link.png);
        }
        div.withIcon {
          cursor: pointer;
          display: flex;
          div.copyIcon {
            background-position: center;
            margin-left: 10px;
            opacity: 0;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-image: url(../../../static/imgs/copy.png);
            &.copied {
              animation-name: poomph;
              animation-duration: 0.55s;
              background-size: 80%;
              background-repeat: no-repeat;
              background-image: url(../../../static/imgs/checked.png);
            }
          }
        }
      }
      .title {
        flex-grow: 2;
      }
      .status {
        flex-grow: 1;
        justify-content: flex-start;
        &.without_access { color: #979797; }
        &.process { color: #F2994A; }
        &.complete { color: #00796B; }
        &.failed { color: #D50000; }
      }
      .date {
        flex-grow: 2;

      }
    }
    .invite + .invite {
      border-top: 1px solid #eaeaea;
    }
  }
  .links_wrapper {
    flex-grow: 14;
    .link-create__wrapper {
      .pharm_info {
        display: flex;
        align-items: flex-end;
        margin: 20px;
        margin-bottom: 0;
        * + * {
          margin-left: 20px;
        }
        .name-header {
          font-size: 24px;
          font-weight: 600;
        }
        .address {
          font-size: 20px;
          color: #979797;
        }
        .count {
          font-size: 16px;
        }
      }
      .link-create__controls {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #eaeaea;
        * {
          flex-grow: 5;
        }
        div + div {
          margin: 0 20px;
        }
        .button {
          flex-grow: 1 !important;
          background-color: #00796B;
          color: white;
          &.save {
            width: 300px !important;
            margin-left: 10px;
            background: white;
            color: #00796B;
            font-size: 9px !important;
          }
        }
      }
    }
  }
}
.link__delete-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 1em;
  opacity: 0.5;
  &:hover {
    transition: 0.3s;
    transform: scale(1.1);
  }
}
