@charset "UTF-8";
h1 {
  font-family: Roboto, sans-serif;
  font-style: Light;
  font-size: 9.6em;
  color: #263238; }

h2 {
  font-family: Roboto, Raleway, sans-serif;
  font-style: SemiBold;
  font-weight: 500;
  font-size: 1.5rem;
  color: #263238; }

h3 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-weight: 500;
  font-size: 3rem;
  color: #263238; }

h4 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 2.125rem;
  color: #263238; }

h5 {
  font-family: Roboto, sans-serif;
  font-style: SemiBold;
  font-size: 1.5rem;
  color: #263238; }

h6 {
  font-family: Raleway, sans-serif;
  font-style: Light;
  font-size: 1.25rem;
  font-weight: 500;
  color: #263238; }

.body1 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 16px;
  color: #263238; }

.body2 {
  font-family: Roboto, sans-serif;
  font-style: Medium;
  font-size: 14px;
  color: #2632389c;
  opacity: 54; }

.subtitle1 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 12px;
  color: #263238; }

.typo__subtitle2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-size: 0.875rem !important;
  color: #263238; }

button {
  font-family: Raleway, sans-serif;
  font-style: SemiBold;
  font-size: 0.875rem;
  letter-spacing: 0.75px;
  color: #263238; }

.button_outline {
  font-family: Raleway, sans-serif;
  font-style: semiBold;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.75px;
  color: #00796B; }

caption {
  font-family: Raleway, sans-serif;
  font-style: SemiBold;
  font-size: 0.75rem; }

.overline {
  font-family: Roboto, sans-serif;
  font-style: Medium;
  font-size: 0.625; }

.root__mobile {
  text-size-adjust: none; }
  @media (orientation: portrait) {
    .root__mobile .header:after {
      position: absolute;
      top: 45%;
      padding: 1rem;
      content: 'Переведите устройство в горизонтальное положение';
      font-family: Roboto, sans-serif;
      font-style: Regular;
      font-size: 1.4rem;
      text-align: center; }
    .root__mobile .content, .root__mobile .userActions {
      display: none; } }
  .root__mobile .header {
    display: grid;
    padding: 0;
    justify-content: space-between; }

.courses__mobile .course-info-header {
  margin: 5px 0;
  text-transform: uppercase;
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.courses__mobile .container {
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1em;
  justify-items: center;
  padding: 1em;
  overflow-x: hidden; }
  .courses__mobile .container .course .course__content {
    height: 100%; }
