$spacing_unit: 8;

.search {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}
.searchIcon {
  z-index: 999;
  height: 100%;
  position: absolute;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
  opacity: 0.7;
  &_clear {
    right: 0;
    margin-right: 7px;
    margin-left: 0;
    &:hover {
      cursor: pointer;
      opacity: 0.4;
    }
  }
}
.inputInput {
  border: 1px solid #cacaca;
  border-radius: 5px;
  font-size: 0.875rem;
  height: 100%;
  width: 100%;
  color: #979797;
  outline: none;
  padding: 11px 8px;
  padding-left: 50px;
  &_clear {
    padding-right: 40px;
  }
}
.searchIcon_clear {
  svg {
    opacity: 0.7;
  }
}
