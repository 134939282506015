@import "../../styles/typography";
.cookieAlert__menu {
  align-items: center;
  background-color: #00796B;
  display: flex;
  justify-content: center;
  min-height: 70px;
  position: absolute;
  top: 0;
  width: 100%;
  &.disabled {
    display: none;
  }
  p {
    color: #FFF;
  }
  button {
    border: 2px solid rgba(#FFF, 0.4);
    border-radius: 3px;
    background-color: #00796B;
    color: #FFF;
    cursor: pointer;
    height: 70%;
    margin-left: 12px;
    padding: 8px 12px;
    outline: 0;
  }
}
.stepBar {
  display: flex;
  height: 40px;
  background-color: #E1E2E1;
  .stepBarPoint {
    align-self: flex-end;
    margin-left: 25px;
    padding-bottom: 9px;
    @include training_h2;
    font-weight: normal;
    font-size: 14px;
    &.current {
      color: #009688;
      border-bottom: solid 2px #009688;
    }
  }
  .cancelButton {
    margin-left: auto;
    @include training_h2;
    font-weight: normal;
    font-size: 14px;
  }
}

.test__wrapper {
  background-color: #f5f5f6;
  display: flex;
  height: 100%;
  .component__wrapper {
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 20px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    .test__info {
      border-right: 1px solid #E6E6E6;
      background-color: white;
      min-width: 210px;
      width: 210px;
      padding: 20px;
    }
    .fullscreen {
      width: 100%;
      height: 100%;
    }
    .fullscreen-enabled {
      .fullScreen {
        // display: none;
      }
      h5 {
        padding: 30px 0 15px 0;
      }
      img {
        width: auto;
        height: 70vh;
      }
      .photoDescription {
        padding: 10px;
      }
    }
    .test__question {
      background-color: white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .actionFooter {
      margin-top: auto;
      justify-content: flex-end;
      background-color: white;
      border-top: 1px solid #E6E6E6;
      height: 51px;
      width: 100%;
      align-self: flex-end;
      display: flex;
      @include button_text_1;
      div.button {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        user-select: none;
        background-color: rgba(0, 150, 136, 0.7);
        &:hover {
          background-color: rgba(0, 150, 136, 1);
        }
        &.disabled {
          color: #dadada;
          background-color: #FFF;

          cursor: not-allowed;
        }
      }
      div.loader {
        width: 100px;
      }
    }
  }
}

@media (max-device-width: 1023px) {
  .root__mobile {
    .header {
      // display: none;
    }
    .content {
      .stepBar {
        display: none;
      }
      .test__wrapper {
        .course__wrapper {
          margin: 0;
          .info__wrapper {
            padding: 3%;
            padding-top: 0;
            line-height: 22px;
            h2 {
              font-size: 20px;
            }
            .slidesPreview {
              display: grid;
              grid-template-columns: repeat(auto-fit, 100px);
              grid-gap: 10px;
              margin-bottom: 40px;
              margin-top: 10px;
              .slide {
                margin: 0;
              }
            }
            .agreementRow {
              margin-top: 10px;
              display: grid;
              grid-template-columns: 50px 1fr;
              p {
                font-size: 12px;
                margin-top: auto;
                margin-bottom: auto;
              }
            }
          }
          .course {
            display: none;
          }
          button {
            width: 100%;
          }
        }
        .component__wrapper {
          margin: 0;
          .test__info {
            display: none;
          }
          .test__question {
            .test__wrapper {
              h6 {
                font-size: 18px;
              }
              .body1 > span {
                padding: 7px;
              }
            }
            .pictureTest {
              h5 {
                font-size: 24px;
              }
              img {
                object-fit: cover;
              }
              .actionButton {
                width: 15vh;
                height: 15vh;
              }
              .fullScreen-wrapper, .picture__gallery {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
