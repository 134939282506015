.alert {
  padding: 18px 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 400px;
  &__btn {
    margin-top: 30px;
    border-radius: 3px;
    background: #00796B;
    box-shadow: 0 4px 8px rgba(0, 121, 107, 0.24);
    color: white;
    padding: 12px 0;
    border: none;
    min-width: 160px;
    &:hover {
      cursor: pointer;
      background-color: #005749;
    }
  }
  &__text {
    margin-top: 30px;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
  }
  &__title {
    font-size: 34px;
    color: #D72638;
    letter-spacing: 0.25px;
    font-family: Roboto;
    font-style: normal;
  }
}