@import "../../styles/variables.scss";

.header {
  padding: 8px;
  display: grid;
  grid-template-columns: 64px 287px 1fr 284px;
  div.icon {
    width: 24px;
    height: 24px;
    transition: 0.2s;
    &.rotate { transform: rotateY(180deg); }
  }
  .logo{
    height: 50px;
    width: auto;
    margin-top: 5px;
    margin-left: 5px;
  }
  .userActions{
    grid-column: 4;
    display: flex;
    flex-direction: row;
    justify-self: end;
    align-items: center;
    .desktop{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
    }
    .user{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .userMenu {
      display: none;
      position: absolute;
      top: 70px;
      right: 25px;
      border-radius: 7px;
      border: 1px solid rgba(0,0,0,0.1);
      background: white;
      padding: 20px;
      z-index: 9999;
      &.visible {
        display: flex;
        flex-flow: column nowrap;
      }

      button {
        margin-top: 10px;
      }
      .email {
        color: #9a9a9a;
        font-size: 12px;
      }
      p { margin: 0 40px 0 10px; }
    }
  }
}
