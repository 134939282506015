.delete {
  &__wrapper {
    display: flex;
    align-items: center;
    text-transform: none;
    background-color: inherit;
    padding: 6px 12px;
    color: #00796B;;
    text-align: center;
    font-weight: 500;
    border: 1px solid #00b7a3;
    border-radius: 3px;
    box-shadow: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
  &__icon {
    margin-left: 16px;
  }
}