@import "../../styles/typography.scss";

.stepBar {
  display: flex;
  height: 40px;
  background-color: #E1E2E1;
  .stepBarPoint {
    align-self: flex-end;
    margin-left: 25px;
    padding-bottom: 9px;
    @include training_h2;
    font-weight: normal;
    font-size: 14px;
    &.current {
      color: #009688;
      border-bottom: solid 2px #009688;
    }
  }
  .cancelButton {
    margin-left: auto;
    @include training_h2;
    font-weight: normal;
    font-size: 14px;
  }
}

.test__wrapper {
  background-color: #f5f5f6;
  display: flex;
  height: 100%;
  .mobileIcon {
    // margin-top: 3vh !important;
  }
  .component__wrapper {
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 20px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    .test__info {
      border-right: 1px solid #E6E6E6;
      background-color: white;
      min-width: 210px;
      width: 210px;
      padding: 20px;
    }
    .fullscreen {
      width: 100%;
      height: 100%;
    }
    .fullscreen-enabled {
      .fullScreen {
        // display: none;
      }
      h5 {
        padding: 30px 0 15px 0;
      }
      img {
        width: auto;
        height: 70vh;
      }
      .photoDescription {
        padding: 10px;
      }
    }
    .test__question {
      background-color: white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .actionFooter {
      margin-top: auto;
      justify-content: space-between;
      background-color: white;
      border-top: 1px solid #E6E6E6;
      height: 51px;
      width: 100%;
      align-self: flex-end;
      display: flex;
      @include button_text_1;
      div.button {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        user-select: none;
        background-color: rgba(0, 150, 136, 0.7);
        &:hover {
          background-color: rgba(0, 150, 136, 1);
        }
        &.disabled {
          color: #dadada;
          background-color: #FFF;

          cursor: not-allowed;
        }
      }
      div.loader {
        width: 100px;
      }
    }
  }
}
