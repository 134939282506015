* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
}

.MuiPaper-elevation2-24, .MuiPaper-elevation4-26 {
  box-shadow: none !important;
}
.tipFonSize {
  font-size: 12px !important;
}

.tempDelete {
  width: 100px;
  background: yellow;
}
