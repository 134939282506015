.results {
  display: flex;
  flex-direction: column;
  .results_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .results_card {
      width: 100%;
      max-width: 250px;
      height: 350px;
      margin: 20px;
      padding: 0 20px;
      .results_content {
        min-height: 80%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .results_info {
          .results_info_block {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        .results_cover {
          & > img {
            width: 100%;
            height: 150px;
            //min-height: 100px;
          }
        }
        & > div {
          margin: 10px 0;
        }
      }
      .results_actions {
        min-height: 20%;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        & > div {
          margin: 10px;
        }
      }
    }
  }
}
