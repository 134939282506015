@import "../styles/typography.scss";
.sss {
  background-color: red;
}
.courses {
  .course-info-header {
    margin: 0 0 20px 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-end;
    &__title {
      margin: 20px 0 5px 30px;
      text-transform: uppercase;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
    }
    &__tail {
      display: flex;
      flex-wrap: nowrap;
    }
    &__searches {
      display: flex;
      height: 100%;
      width: 100%;
    }
    &__search {
      max-width: 400px;
      width: 100%;
      margin-right: 20px;
    }
    &__expand {
      margin: 0 10px;
      &_rotate {
        transform: rotate(180deg);
      }
    }
  }
  button {
    margin: auto;
  }
  .container {
    display: grid;
    grid-auto-rows: 1em;
    grid-gap: 1em 2em;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    justify-content: left;
    margin-bottom: 2em;
    padding: 0 60px 0 30px;

    &.editable {
      div.loader {
        width: calc(100% - 300px);
        height: 500px;
      }
    }
  }
}
.course_started {
  margin: 0;
  max-height: 100%;
}
