.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-item {
    color: #00695c !important;
  }
}
