@keyframes closed {
  from {
    transform: translateX(-500px);
    opacity: 1;
  }
  to {
    transform: translateX(0px);
    opacity: 0;
  }
}
@keyframes loading {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

.content__wrapper {
  display: flex;
  .table {
    width: 100%;
  }
  .item:hover {
    background: rgba(224, 224, 224, 1);
    cursor: pointer;
  }
}
