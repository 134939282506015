@import "../../styles/typography.scss";

.fullscreen-enabled {
  .question__wrapper {
    overflow-y: hidden;
  }
}
.question__wrapper {
  margin: auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  .backgroundColor__wrapper {
    background-color: #e1e2e1;
    height: 100%;
    width: 100%;
    h5,
    h6 {
      padding-top: 5px;
      padding-bottom: 5px;
      color: #333;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 30px;
      text-align: center;
    }
    .photoDescription {
      color: #333;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 18px;
      text-align: center;
    }
  }

  .preview_wrapper {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #0004;
    z-index: 999;
    img {
      width: 70%;
    }
  }

  .someAnswer,
  .oneAnswer,
  .pairs {
    width: 100%;
    .matchesColumn {
      // position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 12px;
      .match {
        width: 20px;
        height: 9px;
        &.select {
          background: #b9b8b8;
        }
      }
    }
    .body1 {
      align-items: center;
    }
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 24px;
    }
    .subtitle1 {
      color: #b9b8b8;
    }
  }
  .option {
    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    &__item{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #efefef;
      padding: 5px 14px;
      border-radius: 6px;
      margin-bottom: 15px;
      background-color: white;
      &_pointer {
        cursor: pointer;
      }
      &.selected {
        border: 1px solid #00796b;
        box-shadow: 0px 4px 8px rgba(0, 121, 107, 0.25);
        transition: 0.5s;
      }
    }
  }

  .question__content {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    .text__wrapper {
      width: 100%;
      h5 {
        text-align: center;
        margin: 30px 0 10px;
      }
      .react-mde {
        border: none;
        text-align: center;
      }
      .mde-header {
        display: none;
      }
    }
    .test__wrapper {
      background-color: white;
      display: grid;
      width: 100%;
      align-items: center;
      padding: 40px;
      .nextTestQuestion {
        background: rgba(0, 150, 136, 0.7);
        &:hover {
          background: #009688;
        }
      }
    }
    .dialog {
      &__wrapper {
      }
      &__questions {
        margin: 30px auto 0 auto;
        max-width: 500px;
      }
      &__question {
        margin-top: 20px;
        max-width: 500px;
      }
    }
    .photoHeader {
      display: flex;
      flex-direction: column;
      h5 {
        padding: 0;
      }
    }
    .fullScreen-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      margin: 20px 20px 0;
      &:hover {
        .fullScreen {
          transform: scale(1.1);
        }
      }
      .fullScreen {
        transition: 0.1s;
        margin-left: 7px;
        width: 16px;
        height: 16px;
        background-image: url(../../static/imgs/fullScreen.png);
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  .answerOption {
    display: flex;
    flex-direction: column;
    & > span {
      margin: 1em;
    }
    span {
      display: grid;
      grid-template-columns: auto 1fr;
    }
    img {
      border: 1px solid #e6e6e6;
      width: 10vh;
      height: 10vh;
      object-fit: cover;
      margin: 5px;
    }
  }
  .fillTheGap__body {
    width: 500px;
    @include body1;
    & input {
      @include body1;
      border: none;
      border-bottom: 1px solid #e6e6e6;
      height: 16px;
    }
  }
  .photosGallery {
    margin-left: -50px;
    display: grid;
    grid-template-columns: repeat(4, 15vh);
    grid-template-rows: repeat(2, 20vh);
    grid-gap: 15px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .videoTest {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .video__wrapper {
    width: 100%;
    height: 100%;
    justify-content: center;
    & > div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      & > iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  .picture__wrapper {
    display: flex;
    justify-content: center;
    padding: 10px;
    .actionButtonWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: auto;
    }
    .actionButton {
      width: 6vh;
      height: 6vh;
      border-radius: 50%;
      border: 1px solid #333;
      align-self: center;
      cursor: pointer;
      &.restart {
        margin-right: auto;
        margin-left: 30px;
        margin-top: 20px;
        background-image: url(../../static/imgs/restart.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      &.nextPhoto {
        margin-right: 30px;
        margin-left: auto;
        background-image: url(../../static/imgs/next.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prevPhoto {
        margin-right: auto;
        margin-left: 30px;
        background-image: url(../../static/imgs/next.png);
        transform: rotateY(180deg);
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    img {
      width: 60%;
      height: 50vh;
      object-fit: contain;
    }
  }
  .picture__gallery {
    justify-content: center;
    display: flex;
    padding: 10px;
    // max-width: 95%;

    .leftArrow {
      cursor: pointer;
      background-image: url(../../static/imgs/keyboard_arrow_right_.png);
      background-repeat: no-repeat;
      align-self: center;
      transform: rotate(180deg);
      margin: 0 10px 0 10px;
      width: 24px;
      height: 24px;
    }
    .rightArrow {
      cursor: pointer;
      background-image: url(../../static/imgs/keyboard_arrow_right_.png);
      background-repeat: no-repeat;
      align-self: center;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      z-index: 1;
    }
    .imageGallery {
      &:nth-last-of-type(2) {
        cursor: default;
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 10px;
      width: 100px;
      height: 100px;
      object-fit: cover;
      cursor: pointer;
      opacity: 0.4;

      &.selected {
        opacity: 1;
        cursor: default;
      }
    }
  }
}
